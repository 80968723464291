import React, { Component } from 'react';

import Logo from './header-logo';
import NavLinks from './navlinks';
import MobNavLinks from './mobnavlinks';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isToggleOn: false,
            headerProp: `${this.props.pageStyle}`,
            headerStyle: 'header-nav'
        };
        console.log(this.state.headerStyle)
        // This binding is necessary to make `this` work in the callback
        this.toggleClass = this.toggleClass.bind(this);
        this.listenScrollEvent = this.listenScrollEvent.bind(this);
    }

    toggleClass() {
        console.log(`state ${this.state.isToggleOn}`);

        this.setState(state => (
            {
                isToggleOn: !state.isToggleOn,
            }));
    }

    listenScrollEvent(e) {
        if (window.scrollY > 10) {
            this.setState(state => (
                {
                    headerStyle: 'headerScroll'
                }));
        }
        else {
            if (this.props.pageStyle == null) {
                this.setState(state => (
                    {
                        headerStyle: 'headerScroll'
                    }));
            }
            else {
                this.setState(state => (
                    {
                        headerStyle: this.props.pageStyle
                    }));
            }
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenScrollEvent);
    }
    componentWillMount() {
        if (this.props.pageStyle == null) {
            this.setState(state => (
                {
                    headerStyle: 'headerScroll'
                }
            ));
        }
        else {
            this.setState(state => (
                {
                    headerStyle: `${this.props.pageStyle}`
                }
            ));
        }
        console.log(this.state.headerStyle)
    }

    render() {
        return (
            <div className='nav-container'>
                <nav className={this.state.headerStyle}>
                    <Logo />
                    <NavLinks />
                    <div className='burger' onClick={this.toggleClass}>
                        <div className='line1'></div>
                        <div className='line2'></div>
                        <div className='line3'></div>
                    </div>
                </nav>
                <MobNavLinks
                    activateStatus={
                        this.state.isToggleOn
                            ? 'onmobile activate-mob'
                            : 'onmobile'
                    }
                    action={this.toggleClass}
                />
            </div>
        );
    }
}

export default Header;
