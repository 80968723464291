import { Link } from 'gatsby';
import React from 'react';

const NavLinks = () => (
    <ul className='nav-links'>
        <li>
            <Link to='/' activeClassName='active-link'>
                HOME
            </Link>
        </li>
        <li>
            <Link
                to='/blog'
                activeClassName='active-link'
                partiallyActive={true}>
                BLOG
            </Link>
        </li>
        <li>
            <Link
                to='/events'
                activeClassName='active-link'
                partiallyActive={true}>
                EVENTS
            </Link>
        </li>
        <li>
            <Link to='/about' activeClassName='active-link'>
                ABOUT
            </Link>
        </li>
    </ul>
);

export default NavLinks;
