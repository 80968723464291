// import { Link } from "gatsby"
import React from 'react';

const Footer = () => (
    <footer>
		<div className="topfooter">
			<div className="sect1">
                <h3>Navigation</h3>
				<ul>
					<li><a href="https://plus.fosscommunity.in">Home</a></li>
					<li><a href="https://riot.im/app/#/room/%23plus:matrix.org">Matrix Chatroom</a></li>
					<li><a href="https://www.loomio.org/g/p9JCX308/plus">Loomio Discussions Group</a></li>
					<li><a href="https://web.archive.org/web/20091119064625/http://lists.sarovar.org/cgi-bin/mailman/listinfo/plus-discuss">Old Mailing Archives</a></li>
					<li><a href="http://fosscommunity.in/index.php?title=Palakkad">Wiki</a></li>
					<li><a href="https://web.archive.org/web/20130206071537/http://sarovar.org/projects/plus/">Old Project Page</a></li>
					<li><a href="https://web.archive.org/web/20120206041337/http://plus.sarovar.org/contents/">Contents</a></li>
				</ul>
			</div>
			<div className="sect2">
                <h3>Links</h3>
				<ul>
					<li><a href="http://www.gnu.org/">GNU Project</a></li>
					<li><a href="https://www.fsf.org/">FSF</a></li>
					<li><a href="http://fsf.org.in/">FSF India</a></li>
					<li><a href="https://www.eff.org/">EFF</a></li>
					<li><a href="https://fsug.in/">Free Software User Groups in India</a></li>
					<li><a href="https://wikiwikiweb.de/LugsList/">GLUG/FSUG List</a></li>
					<li><a href="https://www.freelists.org/list/ssug-malappuram">SSUG Malappuram</a></li>
					<li><a href="http://thrissur.fsug.in/">FSUG Thrissur</a></li>
					<li><a href="https://smc.org.in/">SMC</a></li>
				</ul>
			</div>
		</div>
        <div className='footer'>
            <p>PLUS &copy; 2019</p>
        </div>
    </footer>
);

export default Footer;
